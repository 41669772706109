<script setup lang="ts">
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
	canResetPassword: {
		type: Boolean,
	},
	status: {
		type: String,
	},
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};  
</script>

<template>
	<GuestLayout>

		<Head title="Log in" />

		<div v-if="status" class="mb-4 font-medium text-sm text-green-600">
			{{ status }}
		</div>

		<form @submit.prevent="submit">
			<div class="form-floating">
				<input class="form-control" type="text" id="username" v-model="form.email">
				<label for="username">Brugernavn</label>
				<InputError class="mt-2" :message="form.errors.email" />
			</div>

			<div class="mt-4">
				<div class="form-floating">
					<input class="form-control" type="password" id="password" v-model="form.password">
					<label for="password">Kodeord</label>
				</div>
				<InputError class="mt-2" :message="form.errors.password" />
			</div>

			<div class="flex items-center justify-end mt-4">
				<Link v-if="canResetPassword" :href="route('password.request')"
					class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					Glemt adgangskode
				</Link>
				<PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
					Log in
				</PrimaryButton>
			</div>
		</form>
	</GuestLayout>
</template>
